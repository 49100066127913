import React, { FunctionComponent, useContext } from 'react';
import {
  Box, Flex, Button,
  HStack,
  GridItem,
  Grid,
} from '@chakra-ui/react';
import router from 'next/router';
import BasketIcon from '../../basket/basketIcon';
import isMobile from '../../../utils/isMobile';
import NavLink from '../../navigation/navLink';
import { NavigationContext } from '../../../lib/contexts/NavigationContext';
import Logo from '../../logo';
import PreviewNav from '../../navigation/previewNav';
import LoginButton from '../../login/LoginButton';
import ClassBooking from '../../navigation/NavMenus/ClassBooking';
import MyAccountNav from '../../navigation/NavMenus/MyAccount';
import MobileNav from '../../navigation/MobileNav';

const Header: FunctionComponent = (props) => {
  const ref = React.useRef(null);

  const { paths } = useContext(NavigationContext);

  return (
    <Flex
      ref={ref}
      as="nav"
      align="center"
      flexGrow={1}
      padding={3}
      backgroundColor="#ffffff"
      width="100%"
      justifyContent="center"
      {...props}
    >
      <Grid gridTemplateColumns={['200px 1fr', '200px 1fr']} width={['100%', '100%', '90%']} maxWidth={['100%', '100%', '1200px']}>
        <GridItem display="flex" alignItems="center" justifyContent="left">
          <Button onClick={() => router.push(paths.root)} variant="unstyled" h="auto">
            <Logo />
          </Button>
        </GridItem>
        <GridItem display="flex" alignItems="center" justifyContent="right">
          <Box
            mt={{ base: 0, md: 0 }}
            alignItems="center"
          >
            <HStack>

              {!isMobile() && (
                <Box pr={4}>
                  <HStack>
                    <PreviewNav />
                    <NavLink label="About Us" action={() => router.push('https://ukchinaperformingarts.com/about-ukcpa')} />
                    <ClassBooking />
                    {/* <EventBookingNav /> */}
                    <MyAccountNav />
                    <NavLink label="Shop" action={() => router.push('https://ukchinaperformingarts.com/shop')} />
                  </HStack>
                </Box>
              )}

              {!isMobile() && (
                <>
                  <BasketIcon />
                  <LoginButton />
                </>
              )}

              <MobileNav />
            </HStack>
          </Box>
        </GridItem>
      </Grid>
    </Flex>
  );
};

export default Header;
